const data = [
    {
      id: 1,
      question: "What do you need to start working on my project?",
      answer: "It mostly depends on the type of project. But in general, you need a fair idea of what you want, then we can take it from there."
    },
    {
      id: 2,
      question: "How long will my project take to complete?",
      answer: "This depends on the complexity of the project, your available, and your payment. Once you have this sorted out, I will give you a completion date."
    }
  ]

  export default data
import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'
import { HiShare, HiUserGroup } from 'react-icons/hi'


const data = [
    {
        id: 1, 
        icon: <SiAdobexd/>, 
        title: 'UI/UX Design', 
        desc: "Aktuell zeiche ich Wireframes mit Stift und Papier, Mockups erstelle ich bei Bedarf mit AdobeXD, da ich mit der Suite schon länger arbeite. Außerdem lese ich gerade: 'Interaction Design: beyond human-computer interaction' von Helen Sharp, Yvonne Rogers und Jennifer Preece"
    },
    {
        id: 2, 
        icon: <RiReactjsLine/>, 
        title: 'Frontend Development', 
        desc: "Grundlagen von Hypertext Markup Language, Cascading Style Sheets und JavaScript kann ich, sodass ich mich jetzt auf Frontend Frameworks wie beispielsweise React konzentriere."
    },
    {
        id: 3, 
        icon: <FaServer/>, 
        title: 'Backend Development', 
        desc: "Während einem Udemy Kurs über RestAPI's und Tutorials von Coderfoundry.com habe ich PostgreSQL verwendet. C# und .NET habe ich also schonmal angefasst. Der Fokus liegt allerdings gerade auf der Frontend-Entwicklung. Ich freue mich schon darauf wenn ich so weit bin um das Thema erneut anzugehen."
    },
    {
        id: 4, 
        icon: <HiShare/>, 
        title: 'Social-Media-Marketing', 
        desc: 'Derzeit arbeite ich an einem Konzept für mein persönliches Branding. Als Redaktionsleiter betreue ich seit zwei Jahren den Social-Media-Kanal unserer Katastrophenschutzeinheit. Schon davor habe ich als Hobby diverse Online-Communitys begleitet. Darunter war auch ein Online-Magazin, das Beiträge für namenhafte Spielemagazine schreibt.'
    },
    {
        id: 5, 
        icon: <HiUserGroup/>, 
        title: 'Netzwerken', 
        desc: 'Persönliche Kontakte, der Austausch untereinander und ein gutes Netzwerk sind enorm wichtig. Deswegen treffe ich mich in regelmäßigen Abständen mit einer Lerngruppe. Und stimme meine Lernerfahrungen mit Expertinnen und Experten der Branche ab. Vielleicht auch bald mit Ihnen?'
    },
    {
        id: 6, 
        icon: <AiFillAppstore/>, 
        title: '(Web-)App Development', 
        desc: 'Ich arbeite gerade darauf hin eine vollwertige Web-App zu schreiben. Konkretes finden sie weiter unten bei meinen Projekten und Tools.'
    }
]


export default data
import AboutImage from '../../assets/fw_sw.jpg'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About me" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Me</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                    Ich habe eine starke Leidenschaft dafür Menschen zu helfen, das spornt mich an mein Wissen zu erweitern, 
                    zu teilen und bereits Erlerntes zu festigen.<br/>
                    Neues auszuprobieren, über den Horizont zu blicken ist spannend! Deshalb habe ich in den vergangenen Jahren immer mal wieder Online-Tutorials, 
                    Dokumentationen und Kurse genutzt um abseits des Heimnetzwerkes wieder mehr mit Informationstechnologie zu machen.
                </p>
                <p>
                    Neues braucht Zeit! Die Arbeitsstelle auf 75% zu reduzieren, Verantwortungsposition 
                    als Gruppenführer im Katastrophenschutz abzugeben und sich daran zu gewöhnen im Ehrenamt kürzer zu treten war nicht einfach, aber der einzig richtige Weg!<br/>
                    So habe ich nun genug Zeit mich auf den beruflichen Neustart zu konzentrieren!
                </p>
                <p>
                Webseiten und kleine Programme zu schreiben, Lösungen für Menschen zu schaffen, macht wahnsinnig Spaß!
                Deshalb freue ich mich jeden Tag bei der WBS Training meinem Abschluss als Anwendungswentwickler näher zu kommen.
                </p>
                {/*Remember: import CV as reference, 
                add file and attribute download for CV*/}
                <a href="https://langensiepen.dev" className='btn primary'>CV Placeholder<HiDownload/></a>
            </div>
        </div>
    </section>
  )
}

export default About
import data from './data'
import Card from '../../components/Card'
import './services.css'

const Services = () => {
  return (
    <section id="skills">
      <h2>Skills</h2>
      <p>Hier sehen Sie eine Übersicht über meine derzeitigen Kenntnisse aus den verschiedenen Bereichen.</p>
      <div className="container services__container">
        {
          data.map(item => (
            <Card key={item.id} className="service light">
              <div className="service__icon">
                {item.icon}
              </div>
              <div className="service__details">
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
            </Card>
          ))
        }  
      </div>
    </section>
  )
}

export default Services
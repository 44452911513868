import faqs from './data'
import FAQ from './FAQ'
import './faqs.css'

const FAQs = () => {
  return (
    <section id="faqs">
      <h2>Frequently Asked Questions</h2>
      <p>
        Da ich einige Fragen häufiger gestellt bekomme, möchte ich hier gerne ein paar kurze Antworten geben.
        Falls Ihre Frage hier nicht zu finden ist, <bold><a href='#contact'>kontaktieren</a></bold> Sie mich gerne!
      </p>
      <div className="container faqs__container">
        {
          faqs.map(faq => (
            <FAQ key={faq.id} faq={faq} />
          ))
        }
      </div>
    </section>
  )
}

export default FAQs
import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {FaLinkedinIn} from 'react-icons/fa'
import {AiFillGithub} from 'react-icons/ai'

const data = [
    {id: 1, link: 'https://www.instagram.com/a.langensiepen/', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://twitter.com/Lysoteh', icon: <AiOutlineTwitter/>},
    {id: 3, link: 'https://www.linkedin.com/in/amos-langensiepen/', icon: <FaLinkedinIn/>},
    {id: 4, link: 'https://github.com/Lyso95', icon: <AiFillGithub/>}
]

export default data
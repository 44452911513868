import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
/* import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg' */

const data = [
    {
        id: 1,
        category: 'UI & UX',
        image: Image1,
        title: "Projekt-Titel",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'https://langensiepen.dev',
        github: 'https://github.com/lyso95'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "Projekt-Titel 2 (Frontend)",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'https://langensiepen.dev',
        github: 'https://github.com/lyso95'
    }
]


export default data